import React from "react";
import Layout from "../components/layout";
import OurworkFooter from "../components/OurworkFooter";
import { languageType } from "../utils/JSONData";
import "../Assets/styles/ourwork.scss";
import { Link } from "gatsby";
import { Row, Col } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { graphql } from 'gatsby';
import 'react-tabs/style/react-tabs.css';
import Fade from 'react-reveal/Fade';
import Scrolltop from "../components/Scrolltotop/scrolltop";

//strapi URL
import { imagePathURL } from "../utils/JSONData";
import Footer from "../components/Footer/Footer";

const OurWork = ({data}) => {
  // const titleObj = data.allOurWorkTitles.nodes[0].data.attributes;
  // const ourWorkProduct = data.allOurWorkProducts.nodes[0].data;

  const ourWorkProduct = [
    {
      attributes: {
        title: "Finance",
        description: "Trofi: Earn Interest on Crypto App",
        color: "#c94a4b",
        bgimage: { data: { attributes: { url: "/uploads/T_e1bf9b8701.png" } } },
        image: { data: { attributes: { url: "/uploads/Trofi_Image_2043c85102.png" } } },
        language: "[React, Node.js]",
        item: "Mobile Apps",
        pageLink: "trofi",
        backgroundImage: { data: { attributes: { url: "/uploads/brown_arrow_61c7caa6cf.png" } } }
      }
    },
    {
      attributes: {
        title: "Health & Fitness",
        description: "Coral: Relationship Coach App & Web",
        color: "#16467f",
        bgimage: { data: { attributes: { url: "/uploads/C_d77a9def36.png" } } },
        image: { data: { attributes: { url: "/uploads/coral_web_view_351b7ff0de.png" } } },
        language: "[React Native, React Js]",
        item: "Web",
        pageLink: "trofi",
        backgroundImage: { data: { attributes: { url: "/uploads/nevyblue_arrow_52d943f87a.png" } } }
      }
    },
    {
        attributes: {
          title: "Lifestyle",
          description: "Inner Room App",
          color: "#235cca",
          bgimage: { data: { attributes: { url: "/uploads/I_31ebbaf907.png" } } },
          image: { data: { attributes: { url: "/uploads/Inner_Room_Image_f8714c7d78.png" } } },
          language: "[React Native, RDBX]",
          item: "Mobile Apps",
          pageLink: "trofi",
          backgroundImage: { data: { attributes: { url: "/uploads/blue_arrow_0f9dc89402.png" } } }
        }
      },
      {
          attributes: {
            title: "Book & Reference",
            description: "Almost Everything Audiobooks App",
            color: "#f79c50",
            bgimage: { data: { attributes: { url: "/uploads/A_b67d142507.png" } } },
            image: { data: { attributes: { url: "/uploads/Audiobook_Image_299e37e3b4.png" } } },
            language: "[React Native, Node Js]",
            item: "Mobile Apps",
            pageLink: "trofi",
            backgroundImage: { data: { attributes: { url: "/uploads/orange_arrow_685024a536.png" } } }
          }
        }
  ];

  const mapViewDesign = (item) => {
    return (
      <Row className="">
      <Col xs={12} md={6}>
        <Fade left>
        <div className="ourwork_proj_leftview">
          <img src={imagePathURL + item?.attributes.bgimage.data.attributes.url} alt="background" />
          <div
            className="ourwork_proj_description"
            style={{ color: item?.attributes.color }}
          >
            {item?.attributes.description}
          </div>
          <div className="ourwork_title">{item?.attributes.title}</div>
          <div className="d-flex">
            {item?.attributes.language.replace(/\[|\]/g,'').split(',').map((l, i) => {
              return (
                <div key={i}>
                  <button
                    className="ourwork_button"
                    style={{
                      border: `2px solid ${item?.attributes.color}`,
                      color: item?.attributes.color,
                    }}
                  >
                    {l}
                  </button>
                </div>
              );
            })}
          </div>
          {/* <Link to={'/' + item.attributes.pageLink} className="text-decoration-none"> */}
          {/* <Link to={'/ourwork'} className="text-decoration-none">
            <div
              className="ourwork_view_project"
              style={{ color: item?.attributes.color , backgroundImage: `url(${imagePathURL +item.attributes.backgroundImage.data.attributes.url})`}}
            >
              VIEW PROJECT
            </div>
          </Link> */}
        </div>
        </Fade>
      </Col>
      <Col xs={12} md={6}>
        <Fade right>
        <img className="ourwork_proj_image" alt="projects" src={imagePathURL + item?.attributes.image.data.attributes.url} />
        </Fade>
      </Col>
    </Row>
    )
  };

  return (
    <Layout>
      <div className="ourwork_content">
        <Row>
          <Col sm={12} md={6} className="m-auto">
            <div>
              {/* <div className="ourwork_title">{titleObj.title}</div> */}
              <div className="ourwork_title">Every Portfolio Tells a Story!</div>
              <div className="ourwork_details">
                {/* {titleObj.description} */}
                Turn your ideas into reality. Your business idea deserves recognition. We make it happen. Develop mobile apps that delight your customers.
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} className="text-center">
            {/* <img src={imagePathURL + titleObj.image.data.attributes.url} alt="signman" className="ourwork_first-img" /> */}
            <img src={imagePathURL + '/uploads/signman_ebef83d866.png'} alt="signman" className="ourwork_first-img" />
          </Col>
        </Row>
      </div>
      <div>
        <div className="ourwork_work_title">Our Work</div>
        <div className="ourwork_details" style={{ textAlign: "center" }}>
          Browse our service range in various verticals and industries.
        </div>

        <Tabs>
          <TabList> 
            <div className="ourwork_language_content">
              {languageType.map((item, i) => {
                return (
                  <Tab key={i}>
                    <div className="ourwork_language_name">
                      {item?.title}
                    </div>
                  </Tab>
                );
              })}
            </div>
          </TabList>

          <TabPanel>
            {ourWorkProduct.map((item, i) => {
              return (
                <div key={i} className="ourwork_proj_allview">
                  {mapViewDesign(item)}
                </div>
              )
            })}
          </TabPanel>
          <TabPanel>
            {ourWorkProduct.filter(fil => fil.attributes.item === 'Mobile Apps').map((item, i) => {
              return (
                <div key={i} className="ourwork_proj_allview">
                  {mapViewDesign(item)}
                </div>
              )
            })}
          </TabPanel>
          <TabPanel>
          {ourWorkProduct.filter(fil => fil.attributes.item === 'Web').map((item, i) => {
              return (
                <div key={i} className="ourwork_proj_allview">
                  {mapViewDesign(item)}
                </div>
              )
            })}
          </TabPanel>
        </Tabs>
      </div>
      {/* <OurworkFooter /> */}
      <Footer />
      <Scrolltop />
    </Layout>
  );
};

// export const query = graphql`
//   {
//     allOurWorkProducts {
//       nodes {
//         data {
//           attributes {
//             item
//             title
//             color
//             description
//             pageLink
//             language
//             backgroundImage {
//               data {
//                 attributes {
//                   url
//                 }
//               }
//             }
//             bgimage {
//               data {
//                 attributes {
//                   url
//                 }
//               }
//             }
//             image {
//               data {
//                 attributes {
//                   url
//                 }
//               }
//             }
//           }
//         }
//       }
//     }

//     allOurWorkTitles {
//       nodes {
//         data {
//           attributes {
//             title
//             description
//             image {
//               data {
//                 attributes {
//                   url
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;

export default OurWork;