import React from "react";
import { Images } from "../../Assets/images/images";
import "./styles.scss";
import { Row, Col } from "react-bootstrap";
import { useState } from "react";

const OurworkFooter = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file)
  };

  const handleLinkClick = () => {
    window.open("https://www.linkedin.com/company/madhav-techno", "_blank");
  };

  return (
    <>
      <div className="ourworkfooter_get_touch">
        <img src={Images.GetInTouch} alt="getTuch" className="get-touch-image" />
        {/* <div className="ourworkfooter_main_touch">
          <div className="ourworkfooter_left_content">
            <div className="ourworkfooter_title">Get In Touch</div>
            <div className="ourworkfooter_form_content">
              <div>
                <input
                  type="text"
                  name="fullname"
                  className="ourworkfooter_input-field"
                  placeholder="Your full name *"
                />
              </div>
              <div>
                <input
                  type="text"
                  name="fullname"
                  className="ourworkfooter_input-field"
                  placeholder="Your email address *"
                />
              </div>
              <div className="ourworkfooter_phone_country">
                <select name="country" className="ourworkfooter_input-field">
                  <option value="">Select country</option>
                  <option value="India">India</option>
                  <option value="Canada">Canada</option>
                  <option value="UK">UK</option>
                  <option value="USA">USA</option>
                </select>
                <input
                  type="text"
                  name="fullname"
                  style={{ marginLeft: "10px" }}
                  className="ourworkfooter_input-field"
                  placeholder="Phone number *"
                />
              </div>
              <div>
                <input
                  type="text"
                  name="fullname"
                  className="ourworkfooter_input-field"
                  placeholder="Description"
                />
              </div>
              <div className="ourworkfooter_upload_safe">
                <div className="ourworkfooter_upload">
                  <img src={Images.UploadArrow} alt="uploadArrow"/>
                  <div className="ourworkfooter_text">Upload file</div>
                </div>
                <div className="ourworkfooter_safe">
                  <img src={Images.lock} alt="lock"/>
                  <div className="ourworkfooter_text"> Safe and Secure</div>
                </div>
              </div>
              <div className="ourworkfooter_inquiry_content">
                <button className="ourworkfooter_inquiry_button">
                  Send Your Inquiry
                </button>
              </div>
            </div>
          </div>
          <div className="ourworkfooter_right_content">
            <div>
              <div className="ourworkfooter_say_hi_title">Say Hi!</div>
              <div className="ourworkfooter_say_hi_row">
                <img className="ourworkfooter_say_hi_row_img" src={Images.MailImg} alt="mail" />
                <div className="ourworkfooter_say_hi_text">
                  info@MadhavTechnolgies.com
                </div>
              </div>
              <div className="ourworkfooter_say_hi_row">
                <img className="ourworkfooter_say_hi_row_img" src={Images.Call} alt="call"/>
                <div className="ourworkfooter_say_hi_text">+91 12345-67890</div>
              </div>
              <div className="ourworkfooter_say_hi_row">
                <img className="ourworkfooter_say_hi_row_img" src={Images.Skype} alt="skype"/>
                <div className="ourworkfooter_say_hi_text">MadhavTechnolgies</div>
              </div>
            </div>
              <div className="ourworkfooter_say_hi_bottom">
                <img className="ourworkfooter_say_hi_bottom_img" src={Images.linkedin} alt="linkedin"/>
                <img className="ourworkfooter_say_hi_bottom_img" src={Images.github} alt="github"/>
                <img className="ourworkfooter_say_hi_bottom_img" src={Images.facebook} alt="facebook"/>
              </div>
          </div>
        </div>

        <h1>asdadsdas</h1> */}

        <Row className="ourworkfooter_main_touch">
          <Col md={8} sm={12}>
            <div className="ourworkfooter_left_content">
              <div className="ourworkfooter_title">Get In Touch</div>
              <div className="ourworkfooter_form_content">
                <div>
                  <input
                    type="text"
                    name="fullname"
                    className="ourworkfooter_input-field"
                    placeholder="Your full name *"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="fullname"
                    className="ourworkfooter_input-field"
                    placeholder="Your email address *"
                  />
                </div>
                <div className="ourworkfooter_phone_country">
                  <select name="country" className="ourworkfooter_input-field">
                    <option value="">Select country</option>
                    <option value="India">India</option>
                    <option value="Canada">Canada</option>
                    <option value="UK">UK</option>
                    <option value="USA">USA</option>
                  </select>
                  <input
                    type="text"
                    name="fullname"
                    style={{ marginLeft: "10px" }}
                    className="ourworkfooter_input-field"
                    placeholder="Phone number *"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="fullname"
                    className="ourworkfooter_input-field"
                    placeholder="Description"
                  />
                </div>
                <div className="ourworkfooter_upload_safe">
                  <div className="ourworkfooter_upload">
                    <img src={Images.UploadArrow} alt="uploadArrow" />
                    <label htmlFor="fileInput" className="ourworkfooter_text">Upload file</label>
                    <input style={{display:'none'}}
                      type="file"
                      name="fileInput"
                      id="fileInput"
                      className="ourworkfooter_input-field"
                      onChange={handleFileUpload}
                    />
                  </div>
                  <div className="ourworkfooter_safe">
                    <img src={Images.lock} alt="lock" />
                    <div className="ourworkfooter_text"> Safe and Secure</div>
                  </div>
                </div>
                <div className="ourworkfooter_inquiry_content">
                  <button className="ourworkfooter_inquiry_button">
                    Send Your Inquiry
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} sm={12} className="p-0">
            <div className="ourworkfooter_right_content">
              <div>
                <div className="ourworkfooter_say_hi_title">Say Hi!</div>
                <div className="ourworkfooter_say_hi_row">
                  <img className="ourworkfooter_say_hi_row_img" src={Images.MailImg} alt="mail" />
                  <div className="ourworkfooter_say_hi_text" role={"button"}  tabIndex={0} onClick={() => window.location.href = "mailto:contact@madhavtechno.com"} onKeyDown={() => window.location.href = "mailto:contact@madhavtechno.com"}>
                    contact@madhavtechno.com
                  </div>
                </div>
                <div className="ourworkfooter_say_hi_row">
                  <img className="ourworkfooter_say_hi_row_img" src={Images.Call} alt="call" />
                  <div className="ourworkfooter_say_hi_text" role={"button"}  tabIndex={1} onClick={() => window.location.href = "tel:+91-9601292338"}>+91-9601292338</div>
                </div>
                <div className="ourworkfooter_say_hi_row">
                  <img className="ourworkfooter_say_hi_row_img" src={Images.Skype} alt="skype" onClick={() => window.location.href = "skype:live:.cid.a2ef0ee998d7527f?chat"} />
                  <div className="ourworkfooter_say_hi_text"  onClick={() => window.location.href = "skype:live:.cid.a2ef0ee998d7527f?chat"}>MadhavTechnologies</div>
                </div>
              </div>
              <div className="ourworkfooter_say_hi_bottom">
                <img className="ourworkfooter_say_hi_bottom_img" src={Images.linkedin} alt="linkedin" onClick={handleLinkClick} />
                {/* <img className="ourworkfooter_say_hi_bottom_img" src={Images.github} alt="github" />
                <img className="ourworkfooter_say_hi_bottom_img" src={Images.facebook} alt="facebook" /> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OurworkFooter;